<template>
    <div>
        product
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    
</style>