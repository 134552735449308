<template>
    <div v-if="posts">
        <hr>
        <!-- Slideshow para imágenes -->
        <div class="grid">
            <div v-for="(post, index) in posts" :key="post.id" class="page-link-box">
                <div>
                    <p>
                        <b>Name:</b> {{ post.title }} - <br>
                        <b>Status: </b>{{ post.status }} <br>
                        <b>Slug: </b>{{ post.slug }} <br>
                    </p>
                </div>
                <div class="slideshow-container" v-if="post.images && post.images.length > 1">
                    <div v-for="(image, imageIndex) in post.images" :key="imageIndex">
                        <div v-if="imageIndex === slideIndex[index]" :class="`mySlides${index + 1}`">
                            <img :src="image.path" style="width: 100%" />
                        </div>
                    </div>
                    <a @click="plusSlides(-1, index)" class="prev">&#10094;</a>
                    <a @click="plusSlides(1, index)" class="next">&#10095;</a>
                </div>
                <!-- Miniaturas para imágenes -->
                <div class="thumbnails-container" v-if="thumbnails">
                    <div v-for="(image, imageIndex) in post.images" :key="imageIndex"
                        @click="changeSlide(index, imageIndex)">
                        <img :src="image.thumbnail" style="width: 100%" class="thumbnail" />
                    </div>
                </div>
                <div>
                    <button @click="storeFavorite(post.id)" type="button" v-if="post.created_at"
                            class="btn btn-primary">Favorite</button>
                    <router-link :to="'/product/edit/' + post.id" class="btn btn-primary">Editar</router-link>

                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Copy link
                        </button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" v-on:click="btn_copy_url(post.slug,'')">Link</a></li>
                            <li><a class="dropdown-item" v-on:click="btn_copy_url(post.slug,'?pathwsp=wsp')">Link - wsp</a></li>
                            <li><a class="dropdown-item" v-on:click="btn_copy_url(post.slug,'?mode=funnel')">Link - funnel</a></li>
                            <li><a class="dropdown-item" v-on:click="btn_copy_url(post.slug,'?capmpaigntp=black')">Link - Black Friday</a></li>
                            <li><a class="dropdown-item" v-on:click="btn_copy_url(post.slug,'?capmpaigntp=christmas')">Link - Navidad</a></li>
                        </ul>
                    </div>
                    <router-link :to="'/product/delete/' + post.id">Delete</router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { mapState } from 'vuex';
// import axios from "axios";

export default {
    name: "SlideshowImagesAds",
    props: ['posts', 'thumbnails'],
    data() {
        return {
            slideIndex: null,
            pathUrl: this.appDomainApi,
        }
    },
    created() {
    },
    mounted() {
        if (this.posts.length > 0) {
            this.initializeSlideIndex(this.posts);
        }
    },
    methods: {
        plusSlides(n, index) {
            console.log("plusSlides: " + n)
            console.log("plusSlides: " + index)
            const post = this.posts[index];
            console.log(post)
            if (post && post.images && post.images.length > 0) {
                const imagesLength = post.images.length;
                // this.slideIndex[index] = (this.slideIndex[index] + n + imagesLength) % imagesLength;
                this.$set(this.slideIndex, index, (this.slideIndex[index] + n + imagesLength) % imagesLength);
            }
        },
        initializeSlideIndex(posts) {
            console.log("initializeSlideIndex")

            // this.slideIndex = Array.from({ length: this.posts.length }).fill(0);
            this.slideIndex = Array(posts.length).fill(0);

            console.log("this.slideIndex")
            console.log(this.slideIndex)

            this.changeSlideImage();
        },
        storeFavorite(store_id) {
            console.log("storeFavorite");

            // let url = import.meta.env.VITE_API_ADS_V1 + "shop/storeFavorite/" + shop_id;
            let url = this.appDomainApi + "/shops/store-favorite/" + store_id;

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    // this.posts = this.posts.filter(item => item.shop_id !== shop_id);
                });
        },
        changeSlideImage() {
            console.log("changeSlideImage")
            this.posts.forEach((post, index) => {
                if (post.images && post.images.length > 1) {
                    this.slideIndex[index] = 1;
                }
            });
        },
        changeSlide(index, imageIndex) {
            this.slideIndex[index] = imageIndex;
        },
    },
    watch: {
        posts(newPosts) {
            if (newPosts.length > 0) {
                this.initializeSlideIndex(newPosts);
            }
        }
    },
    // computed: {
    //     ...mapState(['shopData']),
    // },
    // computed: {
    //     // convierte las cadenas de imágenes en un array
    //     postsWithImageArray() {
    //         return this.posts.map(post => ({
    //             ...post,
    //             // images: post.images.split(',')
    //             images: post.images ? post.images.split(',') : []
    //         }));
    //     }
    // }
};
</script>

<style>
.thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    /* Espacio entre miniaturas */
    margin-top: 10px;
    overflow-x: auto;
}

.thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}

.grid {
    display: grid;
    grid-template-rows: auto;
    /* gap: 10px; */
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

/* .page-link-box { */
/* background: red; */
/* padding: 10px;
    margin: 5px; */
/* } */

.box-items {
    display: grid;
    place-items: start;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 20px;
}

img {
    vertical-align: middle;
}

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a grey background color */
.prev:hover,
.next:hover {
    background-color: #f1f1f1;
    color: black;
}
</style>