<template>
    <div class="text-center">
        <div class="form-check form-check-inline m-2" v-for="shop in shops" :key="shop.id">
            <input 
                class="form-check-input" 
                type="radio" 
                :name="name"
                :id="inputId+ shop.id" 
                :value="shop.slug"
                v-model="inputValue" 
                @change="handleShopChange" />
            <label class="form-check-label" :for="inputId+ shop.id">{{ shop.name }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name:"shopsStatusInput",
    props: {
        value: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ""
        },
        inputId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            shops: [
                { id: 1, name: "PUBLISHED", slug: "PUBLISHED" },
                { id: 3, name: "DRAFT", slug: "DRAFT" },
            ],
            // localSelectedShop: ''
        };
    },

    methods: {
        handleShopChange() {
            // this.$emit('update:selectedShop', this.selectedShop);
            // this.$emit('shop-changed', this.selectedShop);
            this.$emit('shop-changed', this.localSelectedShop);
        }
    },
    
    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    },
};
</script>

<style scoped>
/* Añade aquí tus estilos si es necesario */
</style>