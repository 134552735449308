<template>
  <div class="container">

    <shop-selector v-model="shopParent.shop" name="shopSelect" inputId="shopSelect" @shop-changed="updateUrlParams">
    </shop-selector>

    <shops-status v-model="shopParent.status" name="shopStatus" inputId="shopStatus" @shop-changed="updateUrlParams">
    </shops-status>

    <productItems />

    <!-- TITLE -->
    <h1 class="text-center">Products
      <div type="button" class="btn btn-primary" v-on:click="openModalCreateOrder">
        <b>+</b>
      </div>
      <div type="button" class="btn btn-primary" v-on:click="openModalProductsNoRelated">
        <b>Related</b>
      </div>
    </h1>

    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedBody" v-model="bodyView">
      <label class="form-check-label" for="flexSwitchCheckCheckedBody">View body</label>
    </div>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedThumbnails"
        v-model="thumbnails">
      <label class="form-check-label" for="flexSwitchCheckCheckedThumbnails">View thumbnails</label>
    </div>

    <SlideshowImageAds :posts="products.data" :thumbnails="thumbnails" />

    <!-- Button trigger modal - CREATE PRODUCT -->
    <a type="button" id="btnModalCreateOrder" hidden class="btn btn-primary" data-bs-toggle="modal"
      data-bs-target="#orderModalCreate">
      Launch demo modal
    </a>
    
    <!-- Button trigger modal - PRODUCT NO RELATED  -->
    <a type="button" id="btnModalProductNoRelated" hidden class="btn btn-primary" data-bs-toggle="modal"
      data-bs-target="#orderModalProductNoRelated">
      Launch demo modal
    </a>

    <!-- Modal - CREATE ORDER -->
    <div class="modal fade" id="orderModalCreate" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="orderModalLabel">Create Product</h5>
            <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
          </div>
          <div class="modal-body">
            <modelCreateProduct ref="createOrderComponent" />
          </div>
          <div class="modal-footer">
            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
            <a type="button" class="btn btn-primary" @click="productSave">Save</a>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal - PRODUCT NO RELATED -->
    <div class="modal fade" id="orderModalProductNoRelated" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="orderModalLabel">Products Related</h5>
            <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
          </div>
          <div class="modal-body">
            <SlideshowImageAds :posts="productsNoRelated.data" :thumbnails="thumbnails" />

          </div>
          <div class="modal-footer">
            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
            <a type="button" class="btn btn-primary" @click="productSave">Save</a>
          </div>
        </div>
      </div>
    </div>
  

  </div>
</template>
<script>
import axios from 'axios'

import productItems from '@/components/products/index.vue'
import SlideshowImageAds from '@/components/Slideshow/ImagesAds.vue'
import ShopSelector from '@/components/input/shopsInput.vue'
import ShopsStatus from '@/components/input/shopsStatusInput.vue'

import modelCreateProduct from '@/components/products/modal/create.vue'

export default {
  name: 'products',
  components: {
    productItems,
    SlideshowImageAds,
    ShopSelector,
    ShopsStatus,
    modelCreateProduct,
  },
  data() {
    return {
      products: [],
      productsNoRelated: [],
      bodyView: true,
      thumbnails: false,
      shopParent: {
        status: 'PUBLISHED',
        search: '',
        shop: 'tiendapapaya',
      },
    }
  },
  created() {
    this.getProducts();
  },
  mounted() {
    this.initParamsFromUrl();
  },
  methods: {
    getProducts() {
      const url = this.appDomainApi + "shops/products/index" + window.location.search;

      axios.get(url,)
        .then(res => {
          console.log(res.data)
          this.products = res.data
        })
        .catch(err => {
          console.error(err);
        })
    },
    getProductsNoRelated() {
      const url = this.appDomainApi + "shops/products/no-related" + window.location.search;

      axios.get(url,)
        .then(res => {
          console.log(res.data)
          this.productsNoRelated = res.data
        })
        .catch(err => {
          console.error(err);
        })
    },
    getProducts00() {
      const url = this.appDomainApi + "shops/products/index";
      const params = {
        shop: this.shopParent.shop,
        redirect: this.redirect
      };
      axios.post(url, params)
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.error(err);
        })
    },
    updateUrlParams() {
      console.log("updateUrlParams")
      // Actualiza la URL cuando cambia el estado de los checkboxes
      window.history.replaceState({}, '', this.urlWithParams);
    },
    initParamsFromUrl() {
      console.log("initParamsFromUrl")
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams)

      // Obtener los parámetros de los checkboxes
      this.shopParent.shop = urlParams.get('shop');
      this.shopParent.status = urlParams.get('status');
      // this.shopParent.search = urlParams.get('search');
      // this.shopParent.shop = urlParams.get('shop');
      console.log(this.shopParent)

      // Obtener los parámetros de paginación
      const page = parseInt(urlParams.get('page')) || 1;
      const pageSize = parseInt(urlParams.get('pageSize')) || 10;
      this.currentPage = page;
      this.pageSize = pageSize;
    },
    openModalCreateOrder() {
      document.getElementById('btnModalCreateOrder').click();
    },
    openModalProductsNoRelated() {
      this.getProductsNoRelated();
      document.getElementById('btnModalProductNoRelated').click();
    },
    productSave() {
      console.log("productSave")

      // const url = this.appDomainApi + "shops/product/json";
      // const params = {
      //   product: this.productJson,
      // };
      // axios.post(url, params)
      //   .then(res => {
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   });
    },

  },
  computed: {
    urlWithParams() {
      const baseUrl = '/products'; // Reemplaza con tu URL base
      const queryParams = [];

      // Agrega cada parámetro si su checkbox está seleccionado
      if (this.shopParent.status) {
        queryParams.push('status=' + this.shopParent.status); // Reemplaza 'param1' y 'value1' con tus valores
      }
      if (this.shopParent.shop) {
        queryParams.push('shop=' + this.shopParent.shop); // Reemplaza 'param1' y 'value1' con tus valores
      }

      // Agrega los parámetros de paginación
      queryParams.push(`page=1`);

      // Combina la URL base con los parámetros
      return baseUrl + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');
    },
  },
}
</script>